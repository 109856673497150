import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";

import Voice from "../components/Voice";

const VoicePage = ({ data }) => (
  <Layout>
    <Voice data={data} />
  </Layout>
);

export default VoicePage;

export const query = graphql`
  query {
    mjprofile: file(relativePath: { eq: "img-voice-profile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
