import styled from "styled-components";

const HrefButtonInverse = styled.a`
  display: inline-block;
  border: 0;
  padding: 1rem 1.25rem;
  background: transparent;
  color: black;
  box-shadow: inset 0px 0px 0px 2px black;
  border-radius: 2px;
  margin-bottom: 1rem;
  text-decoration: none;
  transition: all 200ms;
  &:hover {
    color: white;
    background: black;
    text-decoration: none;
  }
`;

export default HrefButtonInverse;
