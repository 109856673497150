import styled from "styled-components";
import { rem } from "polished";

const Button = styled.button`
  background: transparent;
  color: white;
  padding: 1rem 1.25rem;
  border-radius: ${rem(3)};
  border: 0;
  box-shadow: inset 0px 0px 0px 2px white;
  margin-bottom: 1rem;
  transition: all 200ms;
  &:hover {
    cursor: pointer;
    background: white;
    color: black;
    box-shadow: inseet 0px 0px 2px #599e33;
  }
`;

export default Button;
