import React from "react";
import Img from "gatsby-image";
import { navigate } from "gatsby";
import styled from "styled-components";

import { Row, Col } from "react-styled-flexboxgrid";
import ReactAudioPlayer from "react-audio-player";
import { Box } from "reflexbox";
import { Primary, Alternate } from "../../components/Section";
import { ButtonGhost } from "../../components/Button";
import { rem } from "polished";
import LogoVoice from "../../images/logo-voice.svg";

import MjVoice from "../../audio/michael-joyner-c2.mp3";

const StyledVoiceLogo = styled.img`
  height: ${rem(400)};
`;

const Voice = ({ data }) => {
  return (
    <section>
      <Primary centered>
        <Row>
          <Col xs={12}>
            <StyledVoiceLogo src={LogoVoice} alt="mjmd voice logo" />
          </Col>
        </Row>
      </Primary>
      <Alternate>
        <Row>
          <Col xs={12} md={6}>
            <h2>MJMD Voice</h2>
            <p>
              Voice services include audiobooks, medical narrations, smooth
              jazzy sounds, and more.
            </p>
            <h3>Demo Reel</h3>
            <ReactAudioPlayer
              src={MjVoice}
              controls
              style={{ backgroundColor: "white" }}
            />
            <Box mt={4} mb={4} />
            <ButtonGhost onClick={() => navigate("/reach-us")}>
              Contact Us
            </ButtonGhost>
          </Col>
          <Col xs={12} md={6}>
            <Img
              fluid={data.mjprofile.childImageSharp.fluid}
              alt="image of dr. joyner"
            />
          </Col>
        </Row>
      </Alternate>
    </section>
  );
};

Voice.propTypes = {};

export default Voice;
